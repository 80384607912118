<template>
  <div>
    <Multiselect
      :value="localValue"
      :label="label"
      :taggable="true"
      :multiple="true"
      :disabled="disabled"
      :options="prepairedOptions"
      :option-label="'subject'"
      :value-prop="'hash'"
      @change="onChange"
    />
  </div>
</template>

<script>
import httpClient from '@/utils/httpClient';
import Multiselect from './Multiselect';
import { format } from 'date-fns';

export default {
  components: {
    Multiselect
  },
  props: {
    inventionId: {
      type: String,
      default: null
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [],
      localValue: this.modelValue.map(c => c.hash)
    };
  },
  computed: {
    prepairedOptions() {
      return this.options.map(c => {
        const date = format(new Date(c.date), 'MM/dd/yyyy/hh:mm');
        return { ...c, value: c.hash, label: `${c.subject} (${c.sender.address}) - ${date}` };
      });
    }
  },
  async created() {
    try {
      const { correspondence } = await httpClient.get(`/api/hub-correspondence/correspondence/${this.inventionId}`);
      this.options = correspondence;
    } catch (e) {}
  },

  methods: {
    onChange(e) {
      this.$emit(
        'update:modelValue',
        this.options.filter(o => e.find(item => item === o.hash))
      );
    }
  }
};
</script>

<style lang="scss" scoped="true">
</style>
